import React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"

const CmsPage = () => (
    <Layout>
        <Seo 
            title="Headless CMS developers" 
            description="Launch Lab offer headless CMS web development in Australia using Gatsby with Contentful, Netlify CMS or Storyblok."
            pathname="/headless-cms/"
            serviceType="Headless CMS website integration and development"
        />

        <Hero
            h1="Headless CMS developers in Sydney"  
            h2="We provide Gatsby website and headless CMS development using Contentful, Storyblok or Netlify CMS."
        /> 
        
        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">Experience</span>
                            <p>We've worked on more than 10 Gatsby websites and many CMS integrations.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Local</span>
                            <p>All of our headless CMS development work is done onshore in Australia.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Static = yay!</span>
                            <p>Static sites hosted on S3, with CDN, means a super fast website that's hassle free &amp; cheap to run.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Secure modern websites with industry leading CMS</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                We develop webites using <Link to="/gatsby-developer/">Gatsby</Link> and where a content management system (CMS) is required our preference is to use industry leading CMS <a href="https://www.contentful.com/">Contentful</a> or <a href="https://www.storyblok.com/home">Storyblok</a>.
                            </p>
                            <p>
                                The result is a secure modern JAMstack website that content marketers love to work on.
                            </p>
                            <p>
                                We do all of our web development work onshore in Sydney and Canberra and offer complementary services like:
                            </p>
                            <ul>
                                <li>web design</li>
                                <li><Link to="/react-developers-sydney-canberra/">React development</Link></li>
                                <li>full stack application development</li>   
                            </ul> 
                            <p>
                                If you're looking for a modern website that loads quickly and is backed by a hassle free easy-to-use CMS we're sure Gatsby, with a headless CMS, will be a great fit for you. 
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all work
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Does my website really need a CMS? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. If you want a incredible website that loads rapidly, is cheap to host, cheap to maintain, is exceptionally secure and you don't foresee the need to edit content on your own then a static Gatsby website will work perfectly for you.</p>
                                <p>Don't let the word 'static' get in the way. It's probably not what you think. Contact us and we can explain further.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Which headless CMS' do you work on? <ChevronDown />
                                </>
                                }
                            >
                                <p>We have experience using Contenful and Netlify CMS and so we prefer to stick to what we know. However, if you wanted to use a headless CMS like Strapi or Storyblok we'd be interested in working with you.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Is a headless CMS better than Wordpress? <ChevronDown />
                                </>
                                }
                            >
                                <p>Wordpress can actually be used as a headless CMS. Launch Lab doesn't take on Wordpress development projects, but if you are weighing up Wordpress vs Contentful and are coming from a Wordpress background we're confident you'll feel safe using Contentful, or other headless CMS products, and will find the learning curve very small and the user experience intuitive.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What other CMS development work do you do? <ChevronDown />
                                </>
                                }
                            >
                                <p>We started out as <Link to="/django-developer-sydney-canberra/">Django developers</Link> and we still support a few Django projects. If your project is a great match for Django then we'll still recommend it.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    How do you handle forms on a headless CMS website? <ChevronDown />
                                </>
                                }
                            >
                                <p>We've used products like <a href="https://getform.io/">Get Form</a> before and are happy to use any of the popular form builder products (eg: jotform.com). We've also developed our own cost-effective web form solution for headless CMS' using AWS Lambda, AWS DynamoDB and AWS SES.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <Cta 
            text="Get a web design quote and / or a headless CMS development quote from a local onshore Australian design & development team"
        />
    </Layout>
)

export default CmsPage